.sentry-error-embed-wrapper.sentry-custom-styles {
  z-index: 99999;
  background: none;
  display: flex;
  height: auto;
  overflow: initial;

  .sentry-error-embed {
    margin-top: 15%;
  }

  .message-success {
    margin: 80px 0;
  }

  .form-submit .btn {
    border: none;
    border-radius: 3px;
    min-width: 130px;
    background-color: $colorMonza;
    transition: background-color 250ms ease;
    color: $colorWhite;
    font-size: 0.75rem;
    line-height: 1.5;
    font-family: "opensans", sans-serif;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      background-color: $colorMonza;
    }
  }

  .form-submit .close {
    color: $colorBlueRibbon;
    text-decoration: underline;
    padding: 13px 30px;
    font-size: uc-rem-convert(14);
    line-height: 1;
    cursor: pointer;
  }
}
