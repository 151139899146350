@import "tools";

.buttons {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0;
  margin: uc-rem-convert(-$buttons-gutter);
  clear: both;
  width: calc(100% + #{$buttons-gutter * 2});

  &:first-of-type {
    margin-top: uc-rem-convert(-$buttons-gutter);
  }

  &:last-of-type {
    margin-bottom: uc-rem-convert(-$buttons-gutter);
  }

  li {
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  a,
  .button {
    margin: 0;
    display: block;
  }

  & > select,
  & > input {
    width: auto;
    margin: uc-rem-convert($buttons-gutter);
  }

  & > li,
  & > a,
  & > .button {
    margin: uc-rem-convert($buttons-gutter);
  }

  @each $breakpoint in $breakpoint-classes {
    @include breakpoint($breakpoint) {
      &.#{$breakpoint}-left {
        justify-content: flex-start;
      }
      &.#{$breakpoint}-center {
        justify-content: center;
      }
      &.#{$breakpoint}-right {
        justify-content: flex-end;
      }
      &.#{$breakpoint}-vertical {
        flex-direction: column;
      }
      &.#{$breakpoint}-horizontal {
        flex-direction: row;
      }
      &.#{$breakpoint}-stacked {
        flex-direction: column;

        .button {
          width: calc(100% - #{uc-rem-convert($buttons-gutter * 2)});
        }
      }
      &.#{$breakpoint}-flat {
        flex-direction: row;

        .button {
          width: auto;
        }
      }
    }
  }
}
