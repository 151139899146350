@import "tools";

/***
  Default body text sizes
*/

$xxl-font-size: uc-rem-convert(22);
$xl-font-size: uc-rem-convert(19);
$lg-font-size: uc-rem-convert(18);
$md-font-size: uc-rem-convert(17);
$sm-font-size: uc-rem-convert(15);
$xs-font-size: uc-rem-convert(13);

.text-xxl {
  font-size: $xxl-font-size;
}

.text-xl {
  font-size: $xl-font-size;
}

.text-lg {
  font-size: $lg-font-size;
}

.text-md {
  font-size: $md-font-size;
}

.text-sm {
  font-size: $sm-font-size;
}

.text-xs {
  font-size: $xs-font-size;
}
