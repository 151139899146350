/// Adds a downward-facing triangle as a background image to an element. The image is formatted as an SVG, making it easy to change the color. Because Internet Explorer doesn't support encoded SVGs as background images, a PNG fallback is also included.
/// There are two PNG fallbacks: a black triangle and a white triangle. The one used depends on the lightness of the input color.
///
/// @param {Color} $color [$black] - Color to use for the triangle.
@use "sass:math";

@mixin background-triangle($color: $black) {
  $rgb: "rgb%28#{round(red($color))}, #{round(green($color))}, #{round(blue($color))}%29";

  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: #{$rgb}'></polygon></svg>");
}

/// Applies the micro clearfix hack popularized by Nicolas Gallagher. Include this mixin on a container if its children are all floated, to give the container a proper height.
/// The clearfix is augmented with specific styles to prevent borders in flexbox environments
/// @link http://nicolasgallagher.com/micro-clearfix-hack/ Micro Clearfix Hack
/// @link http://danisadesigner.com/blog/flexbox-clear-fix-pseudo-elements/ Flexbox fix
@mixin clearfix {
  &::before,
  &::after {
    display: table;
    content: " ";
    flex-basis: 0;
    order: 1;
  }

  &::after {
    clear: both;
  }
}

/// Adds CSS for a "quantity query" selector that automatically sizes elements based on how many there are inside a container.
/// @link http://alistapart.com/article/quantity-queries-for-css Quantity Queries for CSS
///
/// @param {Number} $max - Maximum number of items to detect. The higher this number is, the more CSS that's required to cover each number of items.
/// @param {Keyword} $elem [li] - Tag to use for sibling selectors.
@mixin auto-width($max, $elem: li) {
  @for $i from 2 through $max {
    &:nth-last-child(#{$i}):first-child,
    &:nth-last-child(#{$i}):first-child ~ #{$elem} {
      width: percentage(math.div(1, $i));
    }
  }
}

/// Removes the focus ring around an element when a mouse input is detected.
@mixin disable-mouse-outline {
  [data-whatinput="mouse"] & {
    outline: 0;
  }
}

/// Makes an element visually hidden, but still accessible to keyboards and assistive devices.
/// @link http://snook.ca/archives/html_and_css/hiding-content-for-accessibility Hiding Content for Accessibility
/// @link http://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/// Reverses the CSS output created by the `element-invisible()` mixin.
@mixin element-invisible-off {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

/// Vertically centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/// Horizontally centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/// Absolutely centers the element inside of its first non-static parent,
/// @link http://www.sitepoint.com/centering-with-sass/ Centering With Sass
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/// Iterates through breakpoints defined in `$breakpoint-classes` and prints the CSS inside the mixin at each breakpoint's media query. Use this with the grid, or any other component that has responsive classes.
@mixin each-breakpoint() {
  $list: $breakpoint-classes;
  @each $name in $list {
    $oldsize: null;

    @if global-variable-exists(size) {
      $oldsize: $size;
    }

    $size: $name !global;

    @include breakpoint($name) {
      @content;
    }

    $size: $oldsize !global;
  }
}

/// Generate the `@content` passed to the mixin with a value `$bp-value` related to a breakpoint, depending on the `$name` parameter:
/// - For a single value, `$bp-value` is this value.
/// - For a breakpoint name, `$bp-value` is the corresponding breakpoint value in `$map`.
/// - For "auto", `$bp-value` is the corresponding breakpoint value in `$map` and is passed to `@content`, which is made responsive for each breakpoint of `$map`.
/// @param {Number|Array|Keyword} $name [auto] - Single value, breakpoint name, or list of breakpoint names to use. "auto" by default.
/// @param {Number|Map} $map - Map of breakpoints and values or single value to use.
@mixin breakpoint-value($name: auto, $map: null) {
  @if $name == auto and type-of($map) == "map" {
    // "auto"
    @each $k, $v in $map {
      @include breakpoint($k) {
        @include breakpoint-value($v, $map) {
          @content;
        }
      }
    }
  } @else {
    // breakpoint name
    @if type-of($name) == "string" {
      $name: get-bp-val($map, $name);
    }

    // breakpoint value
    $bp-value: $name !global;
    @content;
  }
}
