@import "tools";

.tag {
  @include tag($tag-padding: $tag-padding, $tag-font-size: $tag-font-size, $tag-background: $tag-background);
  @each $name, $color in $tag-palette {
    &.#{$name} {
      background: $color;
      color: color-pick-contrast($color, ($tag-color, $tag-color-alt));
    }
  }
}
