@import "tools";

.menu {
  @include menu-base;
  // Default orientation: horizontal
  @include menu-direction(horizontal);

  // Breakpoint specific versions
  @each $breakpoint in $breakpoint-classes {
    @include breakpoint($breakpoint) {
      &.#{$breakpoint}-horizontal {
        @include menu-direction(horizontal);
      }

      &.#{$breakpoint}-vertical {
        @include menu-direction(vertical);
      }

      &.#{$breakpoint}-expanded {
        @include menu-expand;
      }

      &.#{$breakpoint}-simple {
        @include menu-expand;
      }
    }
  }

  // Nesting
  &.nested {
    @include menu-nested;
  }

  // Active state
  .is-active > a {
    @include menu-state-active;
  }

  // Align left
  &.align-#{$global-left} {
    @include menu-align(left);
  }

  // Align right
  &.align-#{$global-right} {
    @include menu-align(right);

    .nested {
      @include menu-nested($nested-alignment: right);
    }
  }

  // Align center
  &align-center {
    @include menu-align(center);
  }

  .menu-text {
    @include menu-text;
  }
}
