$badge-background: color("primary") !default;
$badge-color: color("white") !default;
$badge-color-alt: color("black") !default;
$badge-palette: $colors !default;
$badge-padding: 0.3em !default;
$badge-minwidth: 2.1em !default;
$badge-font-size: uc-rem-convert(6) !default;

@mixin badge(
  $badge-minwidth: $badge-minwidth,
  $badge-padding: $badge-padding,
  $badge-font-size: $badge-font-size,
  $badge-background: $badge-background
) {
  display: inline-block;
  min-width: $badge-minwidth;
  padding: $badge-padding;
  border-radius: 50%;
  font-size: $badge-font-size;
  text-align: center;
  background: $badge-background;
}
