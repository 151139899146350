$button-font-family: inherit !default;
$button-text-transform: inherit !default;
$button-weight: inherit !default;
$button-padding: 0.85em 1em !default;
$button-margin: 0 0 $global-margin 0 !default;
$button-background: color("primary") !default;
$button-background-hover: scale-color($button-background, $lightness: -15%) !default;
$button-color: color("white") !default;
$button-color-alt: color("black") !default;
$button-radius: $global-radius !default;
$button-font-sizes: (
  tny: uc-rem-convert(6),
  sml: uc-rem-convert(7.5),
  default: uc-rem-convert(9),
  lrg: uc-rem-convert(2.5),
) !default;
$button-padding-sizes: (
  tny: uc-rem-convert(6),
  sml: 0.9em 2em,
  default: $button-padding,
  lrg: uc-rem-convert(2.5),
) !default;
$button-opacity-disabled: 0.25 !default;
$button-background-hover-lightness: -20% !default;
$button-transition:
  background-color 0.25s ease-out,
  color 0.25s ease-out !default;
$button-types: $colors !default;

// Internal: flip from margin-right to margin-left for defaults
@if $global-text-direction == "rtl" {
  $button-margin: 0 0 $global-margin $global-margin !default;
}

@mixin button-base {
  @include disable-mouse-outline;

  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: middle;
  margin: $button-margin;
  padding: $button-padding;
  border: 1px solid transparent;
  transition: $button-transition;
  font-family: $button-font-family;
  text-transform: $button-text-transform;
  font-size: map-get($button-font-sizes, default);
  font-weight: $button-weight;
  -webkit-appearance: none;
  line-height: 1;
  cursor: pointer;
}

/// Expands a button to make it full-width.
/// @param {Boolean} $full [true] - Set to `true` to enable the full width behavior. Set to `false` to reverse this behavior.
@mixin button-width($full: true) {
  @if $full {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  } @else {
    display: inline-block;
    width: auto;
    margin: $button-margin;
  }
}

/// Sets the visual style of a button.
/// @param {Color} $background [$button-background] - Background color of the button.
/// @param {Color} $background-hover [$button-background-hover] - Background color of the button on hover. Set to `auto` to have the mixin automatically generate a hover color.
/// @param {Color} $color [$button-color] - Text color of the button. Set to `auto` to automatically generate a color based on the background color.
/// @param {Int} $border-radius [$button-radius] - Border radius of button.
@mixin button-style(
  $background: $button-background,
  $background-hover: $button-background-hover,
  $color: $button-color,
  $background-hover-lightness: $button-background-hover-lightness,
  $border-radius: $button-radius,
  $font-weight: $button-weight,
  $text-transform: $button-text-transform
) {
  @if $color == auto {
    $color: color-pick-contrast($background, ($button-color, $button-color-alt));
  }

  @if $background-hover == auto {
    $background-hover: scale-color($background, $lightness: $background-hover-lightness);
  }

  background-color: $background;
  color: $color;
  border-radius: $border-radius;

  @if $font-weight != $button-weight {
    font-weight: $font-weight;
  }

  @if $text-transform != $button-text-transform {
    text-transform: $text-transform;
  }

  &:hover,
  &:focus {
    background-color: $background-hover;
    color: $color;
  }
}

/// Adds disabled styles to a button by fading the element, reseting the cursor, and disabling pointer events.
/// @param {Color} $background [$button-background] - Background color of the disabled button.
/// @param {Color} $color [$button-color] - Text color of the disabled button. Set to `auto` to have the mixin automatically generate a color based on the background color.
@mixin button-disabled($background: $button-background, $color: $button-color) {
  @if $color == auto {
    $color: color-pick-contrast($background, ($button-color, $button-color-alt));
  }

  opacity: $button-opacity-disabled;
  cursor: not-allowed;

  & &:hover,
  &:focus {
    background-color: $background;
    color: $color;
  }
}

/// Adds all styles for a button. For more granular control over styles, use the individual button mixins.
/// @param {Boolean} $full [false] - Set to `true` to make the button full-width.
/// @param {Color} $background [$button-background] - Background color of the button.
/// @param {Color} $background-hover [$button-background-hover] - Background color of the button on hover. Set to `auto` to have the mixin automatically generate a hover color.
/// @param {Color} $color [$button-color] - Text color of the button. Set to `auto` to automatically generate a color based on the background color.
@mixin button(
  $full: false,
  $background: $button-background,
  $background-hover: $button-background-hover,
  $color: $button-color
) {
  @include button-base;
  @include button-style($background, $background-hover, $color);

  @if $full {
    @include button-width;
  }
}
