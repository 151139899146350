@import "tools";
@import "ng-select/reference-data-selector";
@import "sentry-modal-overlay";
.no-scroll {
  overflow: hidden;
}

uc-page-not-found-template + uc-flash-message .system-message-wrapper {
  display: none;
}
