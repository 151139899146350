@import "tools";

table {
  @include table;
}

table.stack {
  @include breakpoint($table-stack-breakpoint down) {
    @include table-stack;
  }
}

table.scroll {
  @include table-scroll;
}

table.hover {
  @include table-hover;
}

.table-scroll {
  overflow-x: auto;
}
