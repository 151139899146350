$tag-background: color("primary") !default;
$tag-color: color("white") !default;
$tag-color-alt: color("black") !default;
$tag-palette: $colors !default;
$tag-padding: 0.666em 1.1em !default;
$tag-font-size: uc-rem-convert(9) !default;

@mixin tag($tag-padding: $tag-padding, $tag-font-size: $tag-font-size, $tag-background: $tag-background) {
  display: inline-block;
  background-color: $tag-background;
  color: $tag-color;
  font-weight: bold;
  border-radius: 2em;
  font-size: $tag-font-size;
  line-height: 1;
  padding: $tag-padding;
  text-transform: uppercase;
}
