// Inspired by the default themes: https://github.com/ng-select/ng-select/blob/master/src/themes/default.theme.scss

.ng-select {
  &.ng-select-opened {
    > .ng-select-container {
      .ng-arrow {
        top: -2px;
        border-color: transparent transparent $colorOsloGrey;
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent $colorRollingStone;
        }
      }
    }

    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    width: 100%;
    min-height: 44px;
    background-color: $fieldInputBgColor;
    margin: 0;
    border: none;
    color: $fieldInputTextColor;
    line-height: 44px;
    align-items: center;

    .ng-value-container {
      align-items: center;
      padding-left: 10px;

      .ng-placeholder {
        color: $colorSilverSand;
      }
    }

    &.ng-input input {
      font-size: uc-rem-convert(14);
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-input {
          left: 0;
          padding-left: 10px;
          padding-right: 50px;
          top: 5px;
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $colorConcrete;

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-left: 7px;
        padding-top: 5px;

        .ng-value {
          font-size: 0.9em;
          margin-right: 5px;
          margin-bottom: 5px;
          background-color: $colorConcrete;
          border-radius: 2px;
          border: 1px solid $colorWaikawaGray;

          &.ng-value-disabled {
            background-color: $colorConcrete;
            border: 1px solid $colorMercury;

            .ng-value-label {
              padding-left: 5px;
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 0 5px 0 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 0 5px;

            &:hover {
              background-color: $colorWaikawaGray;
            }

            &.left {
              border-right: 1px solid $colorWaikawaGray;
            }

            &.right {
              border-left: 1px solid $colorWaikawaGray;
            }
          }
        }

        .ng-input {
          padding-bottom: 3px;
          padding-left: 3px;
        }

        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $colorOsloGrey;

    .ng-clear {
      &:hover {
        color: $colorPunch;
      }
    }
  }

  .ng-spinner-zone {
    padding-right: 5px;
    padding-top: 5px;
  }

  .ng-arrow-wrapper {
    padding-right: 5px;
    width: 25px;

    .ng-arrow {
      border-color: $colorOsloGrey transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;

      &:hover {
        border-top-color: $colorRollingStone;
      }
    }
  }
}

.ng-dropdown-panel {
  background-color: $colorWhite;
  border: 1px solid $colorSilverSand;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: $colorMercury;
    margin-top: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: $colorMercury;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $colorSilverSand;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $colorSilverSand;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    margin-bottom: 1px;

    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $colorConcrete;
      }

      &.ng-option-selected {
        background-color: $colorConcrete;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: $colorWhite;
      color: rgba(0, 0, 0, 0.87);
      padding: 8px 10px;
      font-size: uc-rem-convert(12);
      font-weight: 400;

      &.ng-option-selected {
        color: $colorMineShaft;
        background-color: $colorConcrete;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $colorConcrete;
        color: $colorMineShaft;
      }

      &.ng-option-disabled {
        color: $colorSilverSand;
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        padding-right: 5px;
        font-size: 80%;
        font-weight: 400;
      }
    }
  }
}

ng-select .ng-value-label {
  font-weight: 400;
}

ng-select.ng-select-typeahead .ng-input input {
  position: relative;
  bottom: 4px;
}
