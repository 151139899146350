$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------

// == Media queries breakpoints
//
// ## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
// ** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
// ** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
// ** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
// ** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 720px !default;
$screen-sm-min: $screen-sm !default;
// ** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
// ** Deprecated `$screen-md` as of v3.0.1
$screen-md: 1024px !default;
$screen-md-min: $screen-md !default;
// ** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
// ** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1440px !default;
$screen-lg-min: $screen-lg !default;
// ** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// Extra large screen / wide desktop
// ** Deprecated `$screen-lg` as of v3.0.1
$screen-xlg: 1920px !default;
$screen-xlg-min: $screen-xlg !default;
// ** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-xlg-desktop: $screen-xlg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xlg-min - 1) !default;

// == Grid system
//
// ## Define your custom responsive grid.

// ** Number of columns in the grid.
$grid-columns: 12 !default;
// ** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 40px !default;
// Navbar collapse
// ** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
// ** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// == Container sizes
//
// ## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (680px + $grid-gutter-width) !default;
// ** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;

// Medium screen / desktop
$container-desktop: (984px + $grid-gutter-width) !default;
// ** For `$screen-md-min` and up.
$container-md: $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop: (1400px + $grid-gutter-width) !default;
// ** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;

// Extra large screen / wide desktop
$container-xlarge-desktop: (1880px + $grid-gutter-width) !default;
// ** For `$screen-lg-min` and up.
$container-xlg: $container-xlarge-desktop !default;
