@import "tools";

.breadcrumbs {
  @include breadcrumbs-container;

  .disabled {
    color: $breadcrumbs-item-color-disabled;
    cursor: not-allowed;
  }
}
