// @import "~@swimlane/ngx-datatable/index.css";
// @import "~@swimlane/ngx-datatable/assets/icons.css";

@import "tools";

.ngx-datatable {
  overflow: visible !important;
  background: color("white");
  border: 1px solid color("concrete");
  border-radius: $global-radius;
  box-shadow: 0 3px 2px 0 color("black", $opacity: 0.1);

  .datatable-body {
    .progress-linear {
      display: block;
      width: 100%;
      height: 8px;
      padding: 0;
      margin: 0;
      position: absolute;

      .container {
        display: block;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 8px;
        transform: translate(0, 0) scale(1, 1);
        background-color: #00abe8;

        .bar {
          animation: query 0.5s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
          transition: -webkit-transform 0.15s linear;
          transition: transform 0.15s linear;
          background-color: #0068a4;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 8px;
        }
      }
    }
  }
}

.datatable-header-cell,
.datatable-body-cell,
.datatable-footer-inner {
  padding: uc-rem-convert(15 5);
}

.datatable-header-cell {
  color: $colorOsloGrey;
  font-weight: bold;
  font-size: uc-rem-convert(11);
}

.datatable-body-cell {
  font-size: uc-rem-convert(12);
  border-top: 1px solid $colorMercury;
}

.datatable-body-cell-label {
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
}

.datatable-header-cell-template-wrap,
.datatable-body-cell-label {
  label {
    font-family: "opensans", sans-serif;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 0;
    color: inherit;
    display: block;
  }
}

.datatable-footer-inner {
  font-size: uc-rem-convert(12);
  color: $colorOsloGrey;
}

.datatable-header-cell .sort-btn {
  margin-left: uc-rem-convert(3);
}

.datatable-body-row:hover {
  background-color: color("mercury");
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

datatable-body-cell.datatable-body-cell:last-child {
  padding-right: 10px;
}

datatable-row-wrapper .datatable-row-detail {
  padding: 20px 35px;
  background-color: $colorConcrete;
}

datatable-body-row.datatable-body-row.row-expanded {
  border-bottom: none;
}

datatable-body-row.datatable-body-row.error-row {
  background-color: $colorPunchTransparent66;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}

/*
  Icon font override
*/

@font-face {
  font-family: data-table;
  src: url("../../../assets/fonts/data-table.eot");
  src:
    url("../../../assets/fonts/data-table.eot?#iefix") format("embedded-opentype"),
    url("../../../assets/fonts/data-table.woff") format("woff"),
    url("../../../assets/fonts/data-table.ttf") format("truetype"),
    url("../../../assets/fonts/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]::before {
  font-family: data-table, sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="datatable-icon-"]::before,
[class*=" datatable-icon-"]::before {
  font-family: data-table, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter::before {
  content: "\62";
}

.datatable-icon-collapse::before {
  content: "\61";
}

.datatable-icon-expand::before {
  content: "\63";
}

.datatable-icon-close::before {
  content: "\64";
}

.datatable-icon-up::before {
  content: "\65";
}

.datatable-icon-down::before {
  content: "\66";
}

.datatable-icon-sort-unset::before {
  content: "\63";
  opacity: 0.5;
}

.datatable-icon-sort::before {
  content: "\67";
}

.datatable-icon-done::before {
  content: "\68";
}

.datatable-icon-done-all::before {
  content: "\69";
}

.datatable-icon-search::before {
  content: "\6a";
}

.datatable-icon-pin::before {
  content: "\6b";
}

.datatable-icon-add::before {
  content: "\6d";
}

.datatable-icon-left::before {
  content: "\6f";
}

.datatable-icon-right::before {
  content: "\70";
}

.datatable-icon-skip::before {
  content: "\71";
}

.datatable-icon-prev::before {
  content: "\72";
}

.empty-row {
  margin: 20px;
}

ul.pager {
  display: inline-flex !important;

  li {
    margin: 0 5px !important;

    &.pages.active,
    &:hover:not(.disabled) {
      background-color: $colorConcrete;
      border-radius: 3px;
      cursor: pointer;
    }

    a {
      text-align: center;
      width: 32px;
      height: 32px;
      text-decoration: none;
      padding: 5px;
    }

    &.disabled a {
      color: $colorSilverSand;
      cursor: default !important;
    }
  }
}
