@import "tools";

.modal-example-navigation-card {
  display: block;
  width: 408px;
  max-width: 100%;
  height: auto;
  margin: 0 0 20px -10px;
}

.modal-save-button {
  display: block;
  width: 195px;
  max-width: 100%;
  height: auto;
  margin: 15px 0 15px 5px;
}

.modal-timeout-clock {
  display: block;
  width: 61px;
  max-width: 100%;
  height: auto;
  margin: 15px 0 20px 5px;
}

.modal-example-fields-image {
  display: block;
  width: 311px;
  max-width: 100%;
  height: auto;
  margin: 20px 0 20px 10px;
}

.modal-mandatory-field-image {
  display: block;
  width: 304px;
  max-width: 100%;
  height: auto;
  margin: 10px 0 20px 10px;
}

.modal-more-information {
  display: block;
  width: 400px;
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.modal-withdraw-images {
  display: flex;
  margin: 20px 10px;

  .separator {
    border-right: 2px solid $colorConcrete;
    margin: 0 20px;
  }

  .separator__horizontal {
    border-bottom: 2px solid $colorConcrete;
    margin: 10px 0;
  }

  .withdraw-enrolment-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.modal-withdraw-image {
  width: 45%;
  height: 100%;
}

.modal-process-validation {
  display: block;
  width: 100%;
  height: auto;
  margin: 20px 0 20px 10px;
}

.modal-uc-form-template {
  display: block;
  margin-bottom: 30px;
}

.modal-full-page {
  margin: 0 auto;
  background: $colorWhite;
}

.modal-form-title {
  font-size: uc-rem-convert(32);
}

.modal-heading-para {
  font-size: uc-rem-convert(14);
  line-height: 1.36;
  padding-bottom: 32px;
}

$section-padding: 24px;

.modal-uc-section-header {
  display: block;
  padding-bottom: $section-padding;
  border-bottom: 1px solid $colorConcrete;
  margin-bottom: 30px;

  p {
    color: $colorAbbey;
    font-size: uc-rem-convert(14);
    line-height: 1.35;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-section-header-title {
  font-size: uc-rem-convert(20);
}

.modal-subsection-question {
  margin-bottom: 10px;
}

.modal-uc-subsection-minor-text {
  display: block;
  font-size: uc-rem-convert(12);
  line-height: 1.4;
  color: $colorRollingStone;
  margin-bottom: 15px;
  font-weight: 400;
}

.modal-uc-subsection {
  display: block;
  font-size: uc-rem-convert(16);
  padding-top: $section-padding;
  padding-bottom: $section-padding - 10px;
  border-bottom: 1px solid $colorConcrete;

  p {
    margin-bottom: 5px;
  }
}

.modal-uc-subsection-text-block {
  display: block;
  font-size: uc-rem-convert(14);
  line-height: 1.35;
  margin-bottom: 15px;
}

.modal-uc-subsection-text-block p {
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.modal-uc-section {
  display: block;
}

.modal-required-asterisk {
  color: $colorMonza;
}

@media (min-width: 960px) {
  .modal-uc-section {
    border: 1px solid $colorMercury;
    border-radius: 3px;
    box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .modal-uc-section-header,
  .modal-uc-subsection {
    padding-left: $section-padding;
    padding-right: $section-padding;
  }

  .modal-uc-section-header {
    padding-top: $section-padding;
    margin-bottom: 0;
  }
}

.modal-open {
  overflow: hidden;
}

modal-overlay {
  background: rgba(69, 73, 76, 0.8);
  z-index: 9999;
  overflow: auto;
}

bs-modal-container {
  margin: 100px auto;
}

bs-modal-container,
.nsm-dialog {
  position: relative !important;
  z-index: 99999;
  background: $colorWhite;
  border-radius: 10px;
  width: 90%;
  max-width: 800px;

  &:focus {
    outline: none;
  }

  .form-control {
    background: white;
    background-color: white;
    border: 1px #e3e3e3 solid;
    border-radius: 3px;
    height: 46px;
    display: block;
    position: relative;
    width: 100%;
    font-size: uc-rem-convert(16);
    padding-left: 8px;
    padding-right: 8px;

    input {
      display: block;
      width: 100%;
      border-radius: 3px 0 0 3px;
      height: 44px;
      background-color: $colorWhite;
      color: $colorAbbey;
      border: none;
      padding: 0 16px;
      font-size: uc-rem-convert(14);
      line-height: 1;
    }
  }

  .btn {
    display: inline-block;
    padding: 13px 30px;
    border: none;
    border-radius: 3px;
    min-width: 130px;
    background-color: $colorMonza;
    color: $colorWhite;
    font-size: uc-rem-convert(12);
    line-height: 1.5;
    font-family: "opensans", sans-serif;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 250ms ease;

    &:hover {
      background: $colorAbbey;
    }
  }

  .cancel-btn {
    border: none;
    background: none;
    color: $colorBlueRibbon;
    text-decoration: underline;
    padding: 13px 30px;
    font-size: uc-rem-convert(14);
    line-height: 1;
    cursor: pointer;
  }

  .close {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 40px;
    right: 40px;
    border: none;
    background: url("../../../assets/img/modal-close.svg") no-repeat center center;
    background-size: cover;
    width: 24px;
    height: 24px;

    span {
      display: none;
    }
  }
}

.nsm-dialog {
  max-width: 600px;
  margin: 0 auto;
  align-self: center;
  height: fit-content;
}

.nsm-dialog.large {
  max-width: 900px;
  margin: 15% auto;
}

.modal-dialog {
  padding: 55px;

  &:focus {
    outline: none;
  }
}

.nsm-content {
  padding: 35px;

  &:focus {
    outline: none;
  }
}

.modal-title {
  font-size: uc-rem-convert(32);
  line-height: 1.2;
  margin: 0 0 20px 0;
  font-family: font("secondary");
  font-weight: 400;
}

.modal-sub-title {
  font-size: uc-rem-convert(22);
  line-height: 1.2;
  margin: 25px 0 20px 0;
  font-family: font("secondary");
  font-weight: 400;
}

.modal-body {
  font-size: uc-rem-convert(16);
  line-height: 1.5;
  margin-bottom: 50px;

  p {
    margin: 0 0 15px 0;
  }

  ul {
    margin: 0 0 15px 0;

    li {
      font-size: uc-rem-convert(14);
    }
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4px;
  transform: translate(-50%, -50%);
  font-size: 0;
  opacity: 0;
  transition: opacity 300ms ease;
  transition-delay: 250ms;

  &.show {
    opacity: 1;
  }
}

.loader-blip {
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  transform: transformZ(0);
  animation: loading 2s infinite;
  border-style: solid;
  border-width: 3px;

  &:nth-child(1n) {
    left: -14px;
    animation-delay: 0s;
  }

  &:nth-child(2n) {
    left: 0;
    animation-delay: 0.2s;
  }

  &:nth-child(3n) {
    left: 14px;
    animation-delay: 0.4s;
  }
}

.modal-footer {
  display: flex;
  flex-direction: column;

  button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto 10px auto;
  }

  .left-buttons {
    // intentional comment to workaround: stylelint rule: block-no-empty
  }

  .right-buttons {
    // intentional comment to workaround: stylelint rule: block-no-empty
  }
}

.pos-1 {
  order: 1;
}

.pos-2 {
  order: 2;
}

.pos-3 {
  margin-left: 10px;
  order: 3;
}

.hidden {
  display: none !important;
}

@keyframes loading {
  0% {
    transform: scale(0.5);
    background: $colorWhite;
  }
  50% {
    transform: scale(1);
    background: transparent;
  }
  100% {
    transform: scale(0.5);
    background: $colorWhite;
  }
}

@media (max-width: 600px) {
  .modal-footer {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .modal-footer {
    flex-direction: row;

    button {
      display: inline-block;
      width: auto;
      max-width: none;
      margin: 0 10px 0 0;
    }
  }

  bs-modal-container .cancel-btn {
    padding: 0 10px;
  }
}

// Color of overlay background
$color-overlay: rgba(0, 0, 0, 0.5) !default;

// Transition time
// !! The same as the hideDelay variable defined in ngx-smart-modal.component.ts
$transition-duration: 0ms !default;

// Transition effect
// linear | ease | ease-in | ease-out | ease-in-out
$transition-timing-function: ease-in-out !default;

// Body overflow when a modal is opened.
// Set it to `auto` if you want to unlock the page scroll when a modal is opened
$opened-modal-body-overflow: hidden !default;

// Body if modal is opened
body.dialog-open {
  overflow: $opened-modal-body-overflow;
}

// Close button in modal
.nsm-dialog-btn-close {
  border: 0;
  background: none;
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
  padding: 20px;
}

// Overlay
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: background-color $transition-duration;
  background-color: transparent;
  z-index: 999;

  &.nsm-overlay-open {
    background-color: $color-overlay;
    overflow: hidden;
    display: flex;
  }

  &.transparent {
    background-color: transparent;
  }
}

.nsm-body {
  position: relative;
  flex: 1 1 auto;

  .modal-body {
    margin-bottom: 24px;
  }

  .modal-footer {
    justify-content: space-between;
  }

  h1 {
    font-family: "opensans", sans-serif;
    font-size: uc-rem-convert(20);
    font-weight: 700;
  }
}
