/// Margin around a breadcrumbs container.
/// @type Number
$breadcrumbs-margin: 0 0 $global-margin 0 !default;

/// Font size of breadcrumb links.
/// @type Number
$breadcrumbs-item-font-size: uc-rem-convert(11) !default;

/// Color of breadcrumb links.
/// @type Color
$breadcrumbs-item-color: color("primary") !default;

/// Color of the active breadcrumb link.
/// @type Color
$breadcrumbs-item-color-current: $black !default;

/// Opacity of disabled breadcrumb links.
/// @type Number
$breadcrumbs-item-color-disabled: $medium-gray !default;

/// Margin between breadcrumb items.
/// @type Number
$breadcrumbs-item-margin: uc-rem-convert(75) !default;

/// If `true`, makes breadcrumb links uppercase.
/// @type Boolean
$breadcrumbs-item-uppercase: true !default;

/// If `true`, adds a seperator between breadcrumb links.
/// @type Boolean
$breadcrumbs-item-separator: true !default;

// If it exists $breadcrumbs-item-slash is used to build $breadcrumbs-item-separator. See the documentation.
@if variable-exists(breadcrumbs-item-slash) {
  $breadcrumbs-item-separator: $breadcrumbs-item-slash;
}

/// Used character for the breadcrumb separator.
/// @type Content
$breadcrumbs-item-separator-item: "/" !default;

/// Used character for the breadcrumb separator in rtl mode.
/// @type Content
$breadcrumbs-item-separator-item-rtl: "\\" !default;

/// Color of breadcrumb item.
/// @type Color
$breadcrumbs-item-separator-color: $medium-gray !default;

// If it exists $breadcrumbs-item-slash-color is used to build $breadcrumbs-item-separator-color. See the documentation.
@if variable-exists(breadcrumbs-item-slash-color) {
  $breadcrumbs-item-separator-color: $breadcrumbs-item-slash-color;
}

/// Adds styles for a breadcrumbs container, along with the styles for the `<li>` and `<a>` elements inside of it.
@mixin breadcrumbs-container {
  @include clearfix;

  margin: $breadcrumbs-margin;
  list-style: none;

  // Item wrapper
  li {
    float: #{$global-left};
    font-size: $breadcrumbs-item-font-size;
    color: $breadcrumbs-item-color-current;
    cursor: default;

    @if $breadcrumbs-item-uppercase {
      text-transform: uppercase;
    }

    @if $breadcrumbs-item-separator {
      // Need to escape the backslash
      $separator: if(
        $global-text-direction == "ltr",
        $breadcrumbs-item-separator-item,
        $breadcrumbs-item-separator-item-rtl
      );

      &:not(:last-child) {
        &::after {
          position: relative;
          margin: 0 $breadcrumbs-item-margin;
          opacity: 1;
          content: $separator;
          color: $breadcrumbs-item-separator-color;
        }
      }
    } @else {
      margin-#{$global-right}: $breadcrumbs-item-margin;
    }
  }

  // Page links
  a {
    color: $breadcrumbs-item-color;

    &:hover {
      text-decoration: underline;
    }
  }
}
