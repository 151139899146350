/***
  Body font: Open Sans
  Font weights: regular, semibold (600), bold
  Font styles: italic (regular only)
*/

.body-font {
  font-family: "opensans", sans-serif;
  font-weight: normal;
  font-size: normal;
}

.body-font-bold {
  font-weight: bold;
}

.body-font-semi {
  font-weight: 600;
}

.body-font-italic {
  font-style: italic;
}

/***
  Accent font: Georgia
*/

.accent-font {
  font-family: "Georgia", "Times New Roman", "Times", serif;
  font-weight: 400;
}
