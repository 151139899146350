$menu-margin: 0 !default;
$menu-nested-margin: $global-menu-nested-margin !default;
$menu-items-padding: $global-menu-padding !default;
$menu-simple-margin: uc-rem-convert(10) !default;
$menu-item-color-active: $white !default;
$menu-item-background-active: color(primary) !default;
$menu-state-back-compat: true !default;
$menu-centered-back-compat: true !default;

/// Creates the base styles for a Menu.
@mixin menu-base {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  li {
    @include disable-mouse-outline;
  }

  a,
  .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: $menu-items-padding;
  }

  // Reset styles of inner elements
  input,
  select,
  a,
  button {
    margin-bottom: 0;
  }

  input {
    display: inline-block;
  }
}

/// Expands the items of a Menu, so each item is the same width.
@mixin menu-expand {
  li {
    flex: 1 1 0;
  }
}

/// Align menu items.
@mixin menu-align($alignment) {
  @if $alignment == left {
    justify-content: flex-start;
  } @else if $alignment == right {
    li {
      display: flex;
      justify-content: flex-end;

      .submenu li {
        justify-content: flex-start;
      }
    }

    &.vertical li {
      display: block;
      text-align: $global-right;

      .submenu li {
        text-align: $global-right;
      }
    }
  } @else if $alignment == center {
    li {
      display: flex;
      justify-content: center;

      .submenu li {
        justify-content: flex-start;
      }
    }
  }
}

/// Sets the direction of a Menu.
/// @param {Keyword} $dir [horizontal] - Direction of the Menu. Can be `horizontal` or `vertical`.
@mixin menu-direction($dir: horizontal) {
  @if $dir == horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  } @else if $dir == vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  } @else {
    @warn 'The direction used for menu-direction() must be horizontal or vertical.';
  }
}

/// Creates a simple Menu, which has no padding or hover state.
/// @param {Keyword} $dir [$global-left] - Direction of the menu. This effects the side of the `<li>` that receives the margin.
/// @param {Number} $margin [$menu-simple-margin] - The margin to apply to each `<li>`.
@mixin menu-simple($dir: $global-left, $margin: $menu-simple-margin) {
  align-items: center;
  li + li {
    margin-#{$dir}: $margin;
  }

  a {
    padding: 0;
  }
}

/// Adds styles for a nested Menu, by adding `margin-left` to the menu.
/// @param {Keyword|Number} $margin [$menu-nested-margin] - Length of the margin.
/// @param {Keyword} $nested-alignment [left] - Alignment of the nested class
@mixin menu-nested($margin: $menu-nested-margin, $nested-alignment: left) {
  @if $nested-alignment == right {
    margin-#{$global-right}: $margin;
    margin-#{$global-left}: 0;
  } @else {
    margin-#{$global-right}: 0;
    margin-#{$global-left}: $margin;
  }
}

@mixin menu-text {
  padding: $global-menu-padding;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

@mixin menu-state-active {
  background: $menu-item-background-active;
  color: $menu-item-color-active;
}
