@import "tools";

.modal-backdrop {
  position: fixed;
  inset: 0 0 0 0;
  background-color: rgb(69 73 76 / 80%);
}

.modal-backdrop.opening {
  animation: modal-backdrop-transition;
  animation-duration: $modal-transition-duration;
  animation-timing-function: $modal-transition-timing-function;
}

.modal-backdrop.closing {
  pointer-events: none;
  animation: modal-backdrop-transition;
  animation-direction: reverse;
  animation-fill-mode: forwards;
  animation-duration: $modal-transition-duration;
  animation-timing-function: $modal-transition-timing-function;
}

@keyframes modal-backdrop-transition {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
