@import "tools";

.button {
  @include button;

  // Sizes
  @each $size, $value in map-remove($button-font-sizes, default) {
    &.size-#{$size} {
      font-size: $value;
    }
  }

  // Sizes
  @each $size, $value in map-remove($button-padding-sizes, default) {
    &.padding-#{$size} {
      padding: $value;
    }
  }

  // Expand
  @each $breakpoint in $breakpoint-classes {
    @include breakpoint(#{$breakpoint}) {
      &.#{$breakpoint}-width-full {
        @include button-width;
      }
      &.#{$breakpoint}-width-fluid {
        @include button-width(false);
      }
    }
  }

  // Disabled style
  &.state-disabled,
  &[disabled] {
    @include button-disabled;
  }

  // Colors
  @each $name, $settings in $button-types {
    &.type-#{$name} {
      @if type-of($settings) == map {
        $background: map-if-key($settings, "background", auto);
        @include button-style(
          $background,
          $background-hover: map-if-key($settings, "background-hover", auto),
          $color: map-if-key($settings, "color", auto),
          $border-radius: map-if-key($settings, "radius", $button-radius),
          $font-weight: map-if-key($settings, "weight", $button-weight),
          $text-transform: map-if-key($settings, "transform", $button-text-transform)
        );

        // Disabled style
        &.state-disabled,
        &[disabled] {
          @include button-disabled($background, auto);
        }
      } @else {
        // assume its a color string value
        @include button-style($settings, auto, auto);

        // Disabled style
        &.state-disabled,
        &[disabled] {
          @include button-disabled($settings, auto);
        }
      }
    }
  }

  uc-icon {
    position: absolute;
    left: 1em;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }

  .btn-text {
    opacity: 1;
    transition: opacity 0.3s ease;

    &.hide {
      opacity: 0;
    }
  }

  &.has-icon .btn-text {
    margin-left: 1em;
  }
}

a.button {
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}
