@import "tools";

.badge {
  @include badge(
    $badge-minwidth: $badge-minwidth,
    $badge-padding: $badge-padding,
    $badge-font-size: $badge-font-size,
    $badge-background: $badge-background
  );
  @each $name, $color in $badge-palette {
    &.color-#{$name} {
      background: $color;
      color: color-pick-contrast($color, ($badge-color, $badge-color-alt));
    }
  }
}
