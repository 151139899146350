@mixin tooltip {
  max-width: 250px;
  background: $colorWhite;
  color: $colorAbbey;
  line-height: 1.35;
  padding: 8px 16px;
  margin-top: -18px;
  position: absolute;
  border: 1px solid $colorSilverSand;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%);
  opacity: 0;
  transform: translateY(-3px);
  transition:
    transform 400ms ease,
    opacity 200ms linear;

  &.show {
    opacity: 1;
    transform: translateY(0);
    z-index: 44;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 10px solid $colorWhite;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid $colorSilverSand;
  }
}
