@import "1-variables/_colors";

@font-face {
  font-family: opensans;
  src: url("../../assets/fonts/opensans-normal.woff2") format("woff2");
  font-weight: 1 1000;
  font-style: normal;
}

@font-face {
  font-family: opensans;
  src: url("../../assets/fonts/opensans-italic.woff2") format("woff2");

  // font-weight: normal;
  font-style: italic;
}

body {
  font-family: $primaryFontFamily;
  font-weight: $primaryFontRegular;
  font-size: uc-rem-convert(14);
  line-height: 1.4;
  color: $colorAbbey;
}

p {
  font-size: uc-rem-convert(14);
  line-height: 1.4;
  margin: 20px 0;
}

ul {
  padding: 0 0 0 20px;
}
