@import "tools";

/***
  Default heading sizes
*/

$h1-font-size: uc-rem-convert(42);
$h2-font-size: uc-rem-convert(36);
$h3-font-size: uc-rem-convert(32);
$h4-font-size: uc-rem-convert(24);
$h5-font-size: uc-rem-convert(20);

h1 {
  font-size: $h1-font-size;
  line-height: 1.4;
  margin-bottom: 24px;
}

h2 {
  font-size: $h2-font-size;
  line-height: 1.4;
  margin-bottom: 10px;
}

h3 {
  font-size: $h3-font-size;
  line-height: 1.4;
  margin-bottom: 10px;
}

h4 {
  font-size: $h4-font-size;
  line-height: 1.4;
  margin-bottom: 10px;
}

h5 {
  font-size: $h5-font-size;
  line-height: 1.4;
  margin-bottom: 10px;
}

.text-black {
  color: $colorAbbey;
}

.text-red {
  color: $colorMonza;
}
