@import "tools";

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.divider {
  position: relative;
  text-align: center;
  margin: 40px auto 30px auto;
}

.divider::before {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: $colorMercury;
  position: absolute;
  top: 9px;
  left: 0;
  z-index: 0;
}

.divider__text {
  display: inline-block;
  position: relative;
  z-index: 1;
  background: $colorWhite;
  color: $colorOsloGrey;
  font-size: uc-rem-convert(14);
  line-height: 16px;
  font-weight: 700;
  padding: 0 12px;
}

.max-width-320 {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}

form {
  margin-bottom: 40px;
}

label {
  font-family: "opensans", sans-serif;
  font-size: uc-rem-convert(12);
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 4px;
  color: $colorAbbey;
  display: block;
}

.create-account-prompt {
  color: $colorAbbey;
  font-size: 16px;
  line-height: 22px;
}

.create-account-prompt a {
  color: $colorBlueRibbon;
  text-decoration: none;
}

.spin {
  display: block !important;
  width: 32px;
  height: 32px;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -16px;
  margin-top: -16px;
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.uc-banner {
  background: $colorFireEngineRed;
  z-index: 100;
  height: 120px;
}

.read-only-text {
  margin-bottom: 25px;
  color: $colorAbbey;
  font-size: uc-rem-convert(14);
  font-weight: bold;
}

.red-list {
  margin: 10px 0 20px 0;
  list-style-type: none;

  li {
    margin: 7px 0;
    line-height: 19px;
    position: relative;
  }

  li::before {
    content: "\B7";
    color: $colorFireEngineRed;
    position: absolute;
    font-size: 32px;
    left: -17px;
  }
}

.icon-loader {
  width: 32px;
  height: 32px;
  fill: $colorOsloGrey;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.skip-to-content-link {
  position: fixed;
  left: -99999px;
  top: -99999px;
  z-index: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:active,
  &:focus {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 99999;
    display: block;
    padding: 10px 15px;
    background: $colorWhite;
    border-radius: 0 0 2px 0;
    box-shadow: 2px 2px 4px rgb(0 0 0 / 20%);
  }
}

.tooltip-btn {
  background: none;
  border: none;
  padding: 0;
}

// Hide input clear and password reveal on IE10+
::-ms-clear,
::-ms-reveal {
  display: none;
}

.env-tag {
  width: 42px;
  height: 37px;
  border-radius: 5px 5px 0 0;
  position: fixed;
  z-index: 10000;
  right: 20px;
  bottom: 0;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  line-height: 37px;
  font-size: 20px;
  font-weight: 700;
  color: $colorWhite;
  text-shadow: 1px 1px 1px #000;
}

.needs-js {
  display: none;
}

.libraryh3lp a,
.libraryh3lp > img {
  position: fixed;
  z-index: 100;
  bottom: 12%;
  right: 0.5%;
}

.sentry-error-embed-wrapper {
  z-index: 99999;
  background: none;

  .sentry-error-embed {
    margin-top: 10%;
  }

  .btn {
    border: none;
    border-radius: 3px;
    min-width: 130px;
    background-color: #c01;
    transition: background-color 250ms ease;
    color: #fff;
    font-size: 0.75rem;
    line-height: 1.5;
    font-family: "opensans", sans-serif;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
  }
}

// TODO remove this when we have a proper fix
uc-learner-search-template {
  .filter-selector .select-content {
    border: none !important;
  }
}

@media (width <= 600px) {
  .env-tag {
    display: none;
  }
}
