@import "tools";

.pagination {
  @include pagination-container;

  .current {
    @include pagination-item-current;
  }

  .disabled {
    @include pagination-item-disabled;
  }

  .ellipsis::after {
    @include pagination-ellipsis;
  }
}

@if $pagination-arrows {
  .pagination-previous a::before,
  .pagination-previous.disabled::before {
    display: inline-block;
    margin-#{$global-right}: uc-rem-convert(5);
    content: "\00ab";
  }

  .pagination-next a::after,
  .pagination-next.disabled::after {
    display: inline-block;
    margin-#{$global-left}: uc-rem-convert(5);
    content: "\00bb";
  }
}
