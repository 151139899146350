@import "tools";

.validation-message {
  margin: 0 0 7px 0;
  &__icon,
  &__text,
  &__link {
    display: inline-block;
    vertical-align: middle;
  }
  &__icon {
    margin-right: 10px;
  }
  &__text {
    font-size: uc-rem-convert(12);
    line-height: 1;
    font-weight: $validation-message-weight;
  }
  &__action {
    margin-left: 5px;
    font-size: uc-rem-convert(12);
    line-height: 1;
    font-weight: $validation-message-weight;
    color: $defaultLinkTextColor;
    text-decoration: underline;
    cursor: pointer;
  }
}
